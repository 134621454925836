import React, { useEffect, useState } from "react";

import axios from "axios";

import "./networking-styles.css";

const Networking = () => {
  const [dataSelect, setDataSelect] = useState([]);

  var currentLocation1 = window.location.href;
  var dec1 = decodeURIComponent(currentLocation1);
  var currentPageUrl1 = new URL(dec1);
  var dniUrl = currentPageUrl1.searchParams.get("dni");
  var idClienteUrl = currentPageUrl1.searchParams.get("idCliente");

  //Estructura del nodo personalizado
  var nodeTemplate = function (data) {
    if (data.validacion_estructura === "0") {
      return `
        <div class="title" style="justify-content: center"><span>${data.name}</span></div>
        <div class="content">${data.title}<p class="id">${data.idcliente}</p></div>
      `;
    } else {
      return `
        <div class="title" style="justify-content: center"><img src="https://i.ibb.co/47BysBq/image-removebg-preview.png" style="width: 30px" alt="iconDiagram"/><span>${data.name}</span></div>
        <div class="content">${data.title}<p class="id">${data.idcliente}</p></div>
      `;
    }
  };

  useEffect(() => {
    function openPageIndicator(idCliente) {
      console.log(idCliente);
      var url = `http://arbol.mundosantanatura.com/indicadores?idCliente=${idCliente}`;
      var opcionesVentana =
        "width=" + screen.width + ",height=" + screen.height;
      let pageInit = window.open(url, "_blank", opcionesVentana);
      pageInit.blur();
    }

    //
    axios
      .post(
        `https://api.mundosantanatura.com/api/ClientStructure/GetLeaderShipIndicator?idCliente=${idClienteUrl}`
      )
      .then((response) => {
        let newData = JSON.parse(response.data.data);//parseamos el JSON que se obtiene a un objeto para poder usarlo
        let idperiodos = newData.Idperiodo;//obtenemos Idperiodo
        let descperiodos = newData.Periodo;//obtenemos Periodo

        //juntamos tanto el array de idperiodos como el array de descperiodos en un solo objeto.
        const objetoUnido = {};
        idperiodos.forEach((clave, index) => {
          objetoUnido[clave] = descperiodos[index];
        });
        console.log(objetoUnido)

        //convertimos en un array de objetos el objeto obtenido, esto para rellenarlo en el select.
        const opciones = Object.entries(objetoUnido)
          .map(([key, value]) => ({
          key: parseInt(key, 10),
          value,
        }))
          .sort((a, b)=>b.key - a.key);//ordenamos de mayor a menor
        setDataSelect(opciones)

        //Obtener el periodo mayor
        let periodoMayor = Math.max(...idperiodos);
        console.log(periodoMayor);
        initFetch(periodoMayor);
      })
      .catch((error) => {
        console.error("Error al obtener datos: ", error);
      });

    let dataStructureClient;
    const initFetch = async (p) => {
      console.log(p);
      try {
        const data = {
          document: dniUrl,
          idp: p,
        };
        const req = await axios.post(
          "https://api.mundosantanatura.com/api/ClientStructure/GetStructureClient",
          data
        );

        dataStructureClient = req.data.data[0];

      } catch (error) {
        console.error(error.response.data);
      }

      $("#chart-container").orgchart({
        data: dataStructureClient,
        // 'nodeTitle': 'name',
        // 'nodeContent': 'title',
        visibleLevel: 1,
        pan: true,
        zoom: true,
        icons: "expandToUp",
        nodeTemplate: nodeTemplate,
        parentNodeSymbol: "fa-solid fa-users",
        createNode: function ($node, data) {
          $node.on("click", function (e) {
            openPageIndicator(data.idcliente);
          });
        },
      });
    };

    const closePopupButton = document.getElementById("close-popup");
    if (closePopupButton !== null) {
      closePopupButton.addEventListener("click", () => {
        const popupOverlay = document.getElementById("popup-overlay");
        popupOverlay.style.display = "none";
      });
    }

  }, []);


  //Función para centrar el chart
  const activateLasers = () => {
    document.querySelector("#chart-container > .orgchart").style.transform =
      "none";
  };

  //Función que se ejecuta cuando cambia el select
  const handleChange =  async  (event) => {
    function openPageIndicator(idCliente) {
      var url = `http://arbol.mundosantanatura.com/indicadores?idCliente=${idCliente}`;
      var opcionesVentana =
        "width=" + screen.width + ",height=" + screen.height;
      let pageInit = window.open(url, "_blank", opcionesVentana);
      pageInit.blur();//quita el foco de la nueva ventana emergente, en este caso de pageInit.
    }

    //obtenemos el valor del option seleccionado
    const valorSeleccionado = event.target.value;

    //Eliminar o limpiar el chart que ya existe.
    let chartContainer = document.getElementById('chart-container');
    if (chartContainer && chartContainer.hasChildNodes()) {
      while (chartContainer.firstChild) {
        chartContainer.removeChild(chartContainer.firstChild);
      }
    }

    //Obtener la data de la estructura del cliente
    let dataStructureClient;
    try {
      const data = {
        document: dniUrl,
        idp: valorSeleccionado,
      };
      const req = await axios.post(
        "https://api.mundosantanatura.com/api/ClientStructure/GetStructureClient",
        data
      );
      dataStructureClient = req.data.data[0];
    } catch (error) {
      console.error(error.response.data);
    }

    $("#chart-container").orgchart({
      data: dataStructureClient,
      // 'nodeTitle': 'name',
      // 'nodeContent': 'title',
      visibleLevel: 1,
      pan: true,
      zoom: true,
      icons: "expandToUp",
      nodeTemplate: nodeTemplate,
      parentNodeSymbol: "fa-solid fa-users",
      createNode: function ($node, data) {
        $node.on("click", function (e) {//Al dar clic a un nodo que se ejecute la función openPageIndicator
          openPageIndicator(data.idcliente);
        });
      },
    });
  };

  return (
    <>
      <div>
        <button onClick={activateLasers}>Centrar</button>
      </div>
      <select id="miSelect" onChange={handleChange}>
        {dataSelect.map(opcion => (
          <option key={opcion.key} value={opcion.key}>
            {opcion.value}
          </option>
        ))}
      </select>
      <div id="chart-container" style={{ overflow: "auto" }}></div>
    </>
  );
};

const script = document.createElement("script");
script.src =
  "https://cdnjs.cloudflare.com/ajax/libs/orgchart/3.1.1/js/jquery.orgchart.min.js";
script.async = true;
document.head.appendChild(script);

export default Networking;
