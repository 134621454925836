import React from "react";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import Home from "./home/home";
// import DefaultChart from "./default-chart/default-chart";
// import PanZoomChart from "./pan-zoom-chart/pan-zoom-chart";
// import CustomNodeChart from "./custom-node-chart/custom-node-chart";
// import ExportChart from "./export-chart/export-chart";
// import DragDropChart from "./drag-drop-chart/drag-drop-chart";
// import EditChart from "./edit-chart/edit-chart";
// import EditNode from "./edit-node/edit-node";
// import Networking from "./networking/networking";

import "./App.css";
import Indicadores from "./indicadores/Indicadores";

const App = () => {
  return (
    <Router>
      <div className="wrapper">
        {/* <nav >
          <NavLink to="/" exact activeClassName="selected">
            Home
          </NavLink>
          <NavLink to="/networking" activeClassName="selected">
            Networking
          </NavLink>
          <NavLink to="/indicadores" activeClassName="selected">
            Indicadores
          </NavLink>
        </nav> */}

        <Route exact path="/" component={Home} />
        {/* <Route path="/networking" component={Networking} /> */}
        <Route path="/indicadores" component={Indicadores} />
      </div>
    </Router>
  );
};

export default App;
