import React, { useState, useEffect } from "react";

import axios from "axios";

import "./indicadores-styles.css";
import ReactPaginate from "react-paginate";

const Indicadores = () => {
  const [data, setData] = useState([]);
  const [dataTableFiltersFollows, setDataTableFiltersFollows] = useState([]);
  const [dataTableFiltersFollows2, setDataTableFiltersFollows2] = useState([]);
  const [datosFiltrados, setDatosFiltrados] = useState([]);

  const [dataTableFiltersZonas, setDataTableFiltersZonas] = useState([]);
  const [dataTableFiltersDep, setDataTableFiltersDep] = useState([]);
  const [dataSelectDep, setDataSelectDep] = useState([]);
  const [dataTableFiltersProv, setDataTableFiltersProv] = useState([]);
  const [dataSelectProv, setDataSelectProv] = useState([]);
  const [dataTableFiltersDis, setDataTableFiltersDis] = useState([]);
  const [dataSelectDist, setDataSelectDis] = useState([]);
  const [dataSelectPeriodoReg, setDataSelectPeriodoReg] = useState([]);
  const [dataSelectRangoPer1, setDataSelectRangoPer1] = useState([]);
  const [dataSelectRangoPer2, setDataSelectRangoPer2] = useState([]);
  const [dataSelectRangoMes, setDataSelectRangoMes] = useState([]);

  const [filtro1, setFiltro1] = useState("");
  const [filtro2, setFiltro2] = useState("");

  const [primeraEjecucion, setPrimeraEjecucion] = useState(true);

  var currentLocation1 = window.location.href;
  var dec1 = decodeURIComponent(currentLocation1);
  var currentPageUrl1 = new URL(dec1);
  var idCliente = currentPageUrl1.searchParams.get("idCliente");
  useEffect(() => {
    // Realiza la solicitud a la API y actualiza el estado con los datos.
    const dataBody = {
      document: idCliente,
      idp: 99,
    };

    axios
      .post(
        `https://api.mundosantanatura.com/api/ClientStructure/GetLeaderShipIndicator?idCliente=${idCliente}`,
        dataBody
      )
      .then((response) => {
        let newData = JSON.parse(response.data.data);
        setData(newData);
      })
      .catch((error) => {
        console.error("Error al obtener datos: ", error);
      });

    axios
      .post(
        `https://api.mundosantanatura.com/api/ClientStructure/GetFollowupSixMonths?idCliente=${idCliente}`,
        dataBody
      )
      .then((response) => {
        let newData = response.data.data;
        console.log(newData);
        console.log(newData.followupSixMonths);
        setDataTableFiltersFollows(newData.followupSixMonths);
        // setDataTableFiltersFollows2(newData.followupSixMonths);
        setDataTableFiltersZonas(newData.zonas);
        setDataTableFiltersDep(newData.departamentos);
        setDataTableFiltersProv(newData.provincias);
        setDataTableFiltersDis(newData.distritos);
        setDataSelectPeriodoReg(newData.orderPeriods);

        const dataFollowupSixMonts = newData.followupSixMonths;
        // let filtroPeriodoReg = [];
        let idFiltroZona = [];
        let filtroZona = [];
        let filtroRangoPer1 = [];
        let filtroRangoPer2 = [];
        let filtroRangoMes = [];
        // dataFollowupSixMonts.forEach((e) => {
        //   filtroPeriodoReg.push(e.periodo_Registro);
        // });
        dataFollowupSixMonts.forEach((e) => {
          idFiltroZona.push(e.idZona);
        });
        dataFollowupSixMonts.forEach((e) => {
          filtroZona.push(e.zona);
        });
        dataFollowupSixMonts.forEach((e) => {
          filtroRangoPer1.push(e.rango_Periodo_1);
        });
        dataFollowupSixMonts.forEach((e) => {
          filtroRangoPer2.push(e.rango_Periodo_2);
        });
        dataFollowupSixMonts.forEach((e) => {
          filtroRangoMes.push(e.rango_Mes);
        });
        // let periodoRegSinDuplicados = [...new Set(filtroPeriodoReg)];
        let idZonaSinDuplicados = [...new Set(idFiltroZona)];
        let zonaSinDuplicados = [...new Set(filtroZona)];
        let rangoPer1SinDuplicados = [...new Set(filtroRangoPer1)];
        let rangoPer2SinDuplicados = [...new Set(filtroRangoPer2)];
        let rangoPer2SinElementosVacios = rangoPer2SinDuplicados.filter(
          (elemento) => elemento !== ""
        );
        let rangoMesSinDuplicados = [...new Set(filtroRangoMes)];
        let rangoMesSinElementosVacios = rangoMesSinDuplicados.filter(
          (elemento) => elemento !== ""
        );
        
        let zonas = idZonaSinDuplicados.map((id, index) => ({ id, nombre: zonaSinDuplicados[index] }));
        console.log(zonas);
        // setDataSelectPeriodoReg(periodoRegSinDuplicados);
        // setDataTableFiltersZonas(zonaSinDuplicados);
        setDataSelectRangoPer1(rangoPer1SinDuplicados);
        setDataSelectRangoPer2(rangoPer2SinElementosVacios);
        setDataSelectRangoMes(rangoMesSinElementosVacios);
        
        setDatosFiltrados(newData.followupSixMonths)
      })
      .catch((error) => {
        console.error("Error al obtener datos: ", error);
      });
  }, []);

  /* --------------------------TABLA 1--------------------------------- */
  /* PARA EL NOMBRE DEL SOCIO */
  const { Lider } = data;
  /* PARA LAS FILAS DE LA TABLA */
  const { Periodo } = data;
  const { Venta_Real } = data;
  const { Venta_AA } = data;
  const { VRvsVAA } = data;
  const { Habilitados } = data;
  const { Reingresos } = data;
  const { Ingresos } = data;
  const { Egresos } = data;
  const { Capitalizacion } = data;
  const { Pedidos } = data;
  const { Porcentaje_Actividad } = data;
  const { Promedio_Sx_Pedido } = data;
  const { Promedio_Sx_Pedido_AF } = data;
  const { Pedidos_AF } = data;
  const { Participacion_AF_VtaReal } = data;
  const { Promedio_Sx_Pedido_RE } = data;
  const { Pedidos_RE } = data;
  const { Participacion_RE_VtaReal } = data;
  const { Promedio_Sx_Pedido_UP } = data;
  const { Pedidos_UP } = data;
  const { Participacion_UP_VtaReal } = data;

  /* ----------------------CANTIDAD DE FILAS POR PAGINA DE LA TABLA------------------------------ */
  const filasPorPaginaOpciones = [25, 50, 75];
  const [filasPorPagina, setFilasPorPagina] = useState(
    filasPorPaginaOpciones[0]
  );
  const [paginaActual, setPaginaActual] = useState(1);

  // const [itemOffset, setItemOffset] = useState(0);

  const indiceInicio = (paginaActual - 1) * filasPorPagina;
  const indiceFin = paginaActual * filasPorPagina;
  const datosPaginados = datosFiltrados.slice(
    indiceInicio,
    indiceFin
  );

  const cambiarPagina = (nuevaPagina) => {
    setPaginaActual(nuevaPagina);
  };

  //change combo 25/50/75
  const cambiarFilasPorPagina = (event) => {
    setFilasPorPagina(parseInt(event.target.value, 10));
    setPaginaActual(1); // Volver a la primera página al cambiar la cantidad de filas por página

    // let paginacion = document.getElementById("divPagination");
    // let liPaginacion = paginacion.children[0].children;
    // liPaginacion[1].classList.toggle('active');
    // cambiarPagina(2);
  };

  const pageCount = Math.ceil(datosFiltrados.length / filasPorPagina);//Math.ceil: Redondea hacia arriba el resultado del cociente obtenido en la división.
/* ----------------------------------------------------------------------------------------------- */

  // let rangoPer1 = '';
  // let rangoMes = '';
  const refresh = () => {
    let selectZona = document.getElementById("idSelectZona").value;
    let selectDep = "";
    let selectProv = "";
    let selectDis = "";
    if (selectZona === "") {
      selectDep = "";
      selectProv = "";
      selectDis = "";
    } else{
      selectDep = document.getElementById("idSelectDep").value;
    }

    if (selectDep === "") {
      selectProv = "";
      selectDis = "";
    } else{
      selectProv = document.getElementById("idSelectProv").value;
    }

    if (selectProv === "") {
      selectDis = "";
    } else{
      selectDis = document.getElementById("idSelectDis").value;
    }

    // let selectDis = document.getElementById("idSelectDis").value;

    let estadoRegistro = document.getElementById("mySelect1").value;
    let periodoRegistro = document.getElementById("mySelect5").value;
    let rangoPer1 = document.getElementById("mySelect6").value;
    let rangoPer2 = document.getElementById("mySelect7").value;

    let rangoMes = document.getElementById("mySelect8").value;

    console.log('--------------------------------------------')
    console.log(datosFiltrados)
    // if(datosFiltrados === []){
    //   console.log("entré en datosFiltrados")
    //   setPrimeraEjecucion(true)
    // }
    console.log(dataTableFiltersFollows)
    // Crear una copia del estado inicial solo en la primera ejecución
    const copiaDatos = [...dataTableFiltersFollows];
    console.log(copiaDatos)
    // if(p.id==="mySelect6"){
    //   rangoPer1=p.value;
    //   console.log('entré en mySelect6');
    // }
    // if(p.id==="mySelect8"){
    //   rangoMes=p.value;
    //   console.log('entré en mySelect8');
    // }
    // const datosFiltrados2 = copiaDatos.filter(
    //   (item) =>
    //     (rangoPer1 === "0" || item.rango_Periodo_1 === rangoPer1) &&
    //     (rangoMes === "0" || item.rango_Mes === rangoMes)
    // );
    const datosFiltrados2 = copiaDatos.filter(
      (item) =>
        (item.idZona.includes(selectZona)) &&
        (item.idDepartamento.includes(selectDep)) &&
        (item.idProvincia.includes(selectProv)) &&
        (item.idDistrito.includes(selectDis)) &&
        (item.estado_Registro.includes(estadoRegistro)) &&
        (item.periodo_Registro.includes(periodoRegistro)) &&
        (item.rango_Periodo_1.includes(rangoPer1)) &&
        (item.rango_Periodo_2.includes(rangoPer2)) &&
        (item.rango_Mes.includes(rangoMes))
    );
    console.log(datosFiltrados2)
    setDatosFiltrados(datosFiltrados2)
    // Actualizar el estado de los datos filtrados
    // setDataTableFiltersFollows2(datosFiltrados);
    // setPrimeraEjecucion(false);
    console.log('--------------------------------------------')
  };

  /*-----------------------------------------------------------------*/
  /* Change Zona */
  const rellenarDepartamentos = (asd) => {
    setDataSelectProv([]);
    setDataSelectDis([]);

    /* Cargar al estado llamado setDataSelectDep la información de los departamentos por zona */
    const depXzona = dataTableFiltersDep.filter((d) => {
      return d.idZona === asd.value;
    });
    setDataSelectDep(depXzona);

    refresh(asd)
    // /* Cargar al estado llamado setDataTableFiltersFollows2 la información de la data general según la zona seleccionada */
    // const asdasd = dataTableFiltersFollows.filter((d) => {
    //   return d.idZona === asd;
    // });
    // setDataTableFiltersFollows2(asdasd);
  };
  /* Change Departamento */
  const rellenarProvincia = (asd) => {
    setDataTableFiltersFollows(dataTableFiltersFollows);
    setDataSelectDis([]);
    /*Cargar al estado llamado setDataSelectProv la información de las provincias segun el departamento seleccionado*/
    const provXdep = dataTableFiltersProv.filter((d) => {
      return d.idDepartamento === asd.value;
    });
    provXdep.sort((a, b) => a.nombreProvincia.localeCompare(b.nombreProvincia));
    console.log(provXdep)
    setDataSelectProv(provXdep);
    // /*Cargar al estado llamado setDataTableFiltersFollows2 la información de la data general según el departamento seleccionado*/
    // const asdasd = dataTableFiltersFollows.filter((d) => {
    //   return d.idDepartamento === asd;
    // });
    // setDataTableFiltersFollows2(asdasd);
    refresh(asd)
  };
  /* Change Provincia */
  const rellenarDistrito = (asd) => {
    /*Cargar al estado llamado setDataSelectDis la información de los distritos segun la provincia seleccionada*/
    const disXprov = dataTableFiltersDis.filter((d) => {
      return d.idProvincia === asd.value;
    });
    disXprov.sort((a, b) => a.nombreDistrito.localeCompare(b.nombreDistrito));
    setDataSelectDis(disXprov);
    // /*Cargar al estado llamado setDataTableFiltersFollows2 la información de la data general según la provincia  seleccionada*/
    // const asdasd = dataTableFiltersFollows.filter((d) => {
    //   return d.idProvincia === asd;
    // });
    // setDataTableFiltersFollows2(asdasd);
    refresh(asd)
  };
  /* Change Distrito */
  const cambiarTablaPorDistrito = (asd) => {
    // /*Cargar al estado llamado setDataTableFiltersFollows2 la información de la data general según el distrito seleccionado*/
    // const asdasd = dataTableFiltersFollows.filter((d) => {
    //   return d.idDistrito === asd;
    // });
    // setDataTableFiltersFollows2(asdasd);
    refresh(asd)
  };

  const dataXRangoPeriodo1 = (asd) => {
    /*Cargar al estado llamado setDataTableFiltersFollows2 la información de la data general según el distrito seleccionado*/
    const asdasd = dataTableFiltersFollows.filter((d) => {
      return d.rango_Periodo_1 === asd;
    });
    setDataTableFiltersFollows2(asdasd);
  };

  const dataXRangoMes = (asd) => {
    /*Cargar al estado llamado setDataTableFiltersFollows2 la información de la data general según el distrito seleccionado*/
    const asdasd = dataTableFiltersFollows.filter((d) => {
      return d.rango_Mes === asd;
    });
    setDataTableFiltersFollows2(asdasd);
  };

  return (
    <main
      style={{
        padding: "41px 10%",
        display: "flex",
        flexDirection: "column",
        gap: "100px",
      }}
    >
      <section className="sectionTablesBody">
        <h2>{Lider && Lider.length > 0 ? Lider[0] : null}</h2>
        <div className="bloqueTabla__body">
          <table
            className="table tableHeadLeft table-condensed display nowrap dataTable no-footer"
            cellSpacing="0"
            width="100%"
          >
            <thead></thead>
            <tbody>
              <tr>
                <td style={{ color: "#000" }}>
                  <strong>Resultado Periodo</strong>
                </td>
                {Periodo && Periodo.length > 0
                  ? Periodo.map((periodo, index) => (
                      <td key={index}>
                        <strong>{periodo}</strong>
                      </td>
                    ))
                  : null}
              </tr>
              <tr>
                <td style={{ color: "#000" }}>Venta Real</td>
                {Venta_Real && Venta_Real.length > 0
                  ? Venta_Real.map((ventaReal, index) => (
                      <td key={index}>{Number(ventaReal).toFixed(2)}</td>
                    ))
                  : null}
              </tr>
              <tr>
                <td style={{ color: "#000" }}>Venta Año Anterior</td>
                {Venta_AA && Venta_AA.length > 0
                  ? Venta_AA.map((ventaAñoAnterior, index) => (
                      <td key={index}>{Number(ventaAñoAnterior).toFixed(2)}</td>
                    ))
                  : null}
              </tr>
              <tr>
                <td style={{ color: "#000" }}>
                  % Venta Real vs Venta Año Anterior
                </td>
                {VRvsVAA && VRvsVAA.length > 0
                  ? VRvsVAA.map((ventaRealVSAñoAnterior, index) => (
                      <td key={index}>{Number(ventaRealVSAñoAnterior).toFixed(2)}%</td>
                    ))
                  : null}
              </tr>
              <tr>
                <td style={{ color: "#000" }}>Habilitados</td>
                {Habilitados && Habilitados.length > 0
                  ? Habilitados.map((habilitados, index) => (
                      <td key={index}>{habilitados}</td>
                    ))
                  : null}
              </tr>
              <tr>
                <td style={{ color: "#000" }}>Reingreso</td>
                {Reingresos && Reingresos.length > 0
                  ? Reingresos.map((reingresos, index) => (
                      <td key={index}>{reingresos}</td>
                    ))
                  : null}
              </tr>
              <tr>
                <td style={{ color: "#000" }}>Ingresos</td>
                {Ingresos && Ingresos.length > 0
                  ? Ingresos.map((ingresos, index) => (
                      <td key={index}>{ingresos}</td>
                    ))
                  : null}
              </tr>
              <tr>
                <td style={{ color: "#000" }}>Egresos</td>
                {Egresos && Egresos.length > 0
                  ? Egresos.map((egresos, index) => (
                      <td key={index}>{egresos}</td>
                    ))
                  : null}
              </tr>
              <tr>
                <td style={{ color: "#000" }}>Capitalizacion</td>
                {Capitalizacion && Capitalizacion.length > 0
                  ? Capitalizacion.map((capitalizacion, index) => (
                      <td key={index}>{capitalizacion}</td>
                    ))
                  : null}
              </tr>
              <tr>
                <td style={{ color: "#000" }}>Pedidos</td>
                {Pedidos && Pedidos.length > 0
                  ? Pedidos.map((pedidos, index) => (
                      <td key={index}>{pedidos}</td>
                    ))
                  : null}
              </tr>
              <tr>
                <td style={{ color: "#000" }}>% Actividad</td>
                {Porcentaje_Actividad && Porcentaje_Actividad.length > 0
                  ? Porcentaje_Actividad.map((porcentajeActividad, index) => (
                      <td key={index}>{Number(porcentajeActividad).toFixed(2)}%</td>
                    ))
                  : null}
              </tr>
              <tr>
                <td style={{ color: "#000" }}>Promedio S/xPedido</td>
                {Promedio_Sx_Pedido && Promedio_Sx_Pedido.length > 0
                  ? Promedio_Sx_Pedido.map((promedioSxPedido, index) => (
                      <td key={index}>{Number(promedioSxPedido).toFixed(2)}</td>
                    ))
                  : null}
              </tr>
              <tr>
                <td style={{ color: "#000" }}>Promedio S/xAfiliación</td>
                {Promedio_Sx_Pedido_AF && Promedio_Sx_Pedido_AF.length > 0
                  ? Promedio_Sx_Pedido_AF.map((promedioSxPedidoAF, index) => (
                      <td key={index}>{Number(promedioSxPedidoAF).toFixed(2)}</td>
                    ))
                  : null}
              </tr>
              <tr>
                <td style={{ color: "#000" }}>Pedidos Afiliación</td>
                {Pedidos_AF && Pedidos_AF.length > 0
                  ? Pedidos_AF.map((pedidosAfiliacion, index) => (
                      <td key={index}>{pedidosAfiliacion}</td>
                    ))
                  : null}
              </tr>
              <tr>
                <td style={{ color: "#000" }}>
                  <strong>% Participación de Afiliaciones en la Venta Total</strong>
                </td>
                {Participacion_AF_VtaReal && Participacion_AF_VtaReal.length > 0
                  ? Participacion_AF_VtaReal.map(
                      (participacionAFVentaReal, index) => (
                        <td key={index}>{Number(participacionAFVentaReal).toFixed(2)}%</td>
                      )
                    )
                  : null}
              </tr>
              <tr>
                <td style={{ color: "#000" }}>Promedio S/xReconsumo</td>
                {Promedio_Sx_Pedido_RE && Promedio_Sx_Pedido_RE.length > 0
                  ? Promedio_Sx_Pedido_RE.map(
                      (Promedio_Sx_Pedido_RE, index) => (
                        <td key={index}>{Number(Promedio_Sx_Pedido_RE).toFixed(2)}</td>
                      )
                    )
                  : null}
              </tr>
              <tr>
                <td style={{ color: "#000" }}>Pedidos Reconsumo</td>
                {Pedidos_RE && Pedidos_RE.length > 0
                  ? Pedidos_RE.map((Pedidos_RE, index) => (
                      <td key={index}>{Pedidos_RE}</td>
                    ))
                  : null}
              </tr>
              <tr>
                <td style={{ color: "#000" }}>
                  <strong>% Participación de Reconsumos en la Venta Total</strong>
                </td>
                {Participacion_RE_VtaReal && Participacion_RE_VtaReal.length > 0
                  ? Participacion_RE_VtaReal.map(
                      (Participacion_RE_VtaReal, index) => (
                        <td key={index}>{Number(Participacion_RE_VtaReal).toFixed(2)}%</td>
                      )
                    )
                  : null}
              </tr>
              <tr>
                <td style={{ color: "#000" }}>Promedio S/xUpgrade</td>
                {Promedio_Sx_Pedido_UP && Promedio_Sx_Pedido_UP.length > 0
                  ? Promedio_Sx_Pedido_UP.map(
                      (Promedio_Sx_Pedido_UP, index) => (
                        <td key={index}>{Number(Promedio_Sx_Pedido_UP).toFixed(2)}</td>
                      )
                    )
                  : null}
              </tr>
              <tr>
                <td style={{ color: "#000" }}>Pedidos Upgrade</td>
                {Pedidos_UP && Pedidos_UP.length > 0
                  ? Pedidos_UP.map((Pedidos_UP, index) => (
                      <td key={index}>{Pedidos_UP}</td>
                    ))
                  : null}
              </tr>
              <tr>
                <td style={{ color: "#000" }}>
                  <strong>% Participación de Upgrades en la Venta Total</strong>
                </td>
                {Participacion_UP_VtaReal && Participacion_UP_VtaReal.length > 0
                  ? Participacion_UP_VtaReal.map(
                      (Participacion_UP_VtaReal, index) => (
                        <td key={index}>{Number(Participacion_UP_VtaReal).toFixed(2)}%</td>
                      )
                    )
                  : null}
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <section className="sectionTablesBody filters">
        <div className="bloqueTabla">
          <div className="bloqueTabla__top">
            <div className="bloqueTabla__top__child">
              <p>Zona</p>
              <select
                id="idSelectZona"
                onChange={(e) => rellenarDepartamentos(e.target)}
              >
                <option value="">Seleccione:</option>
                {dataTableFiltersZonas.map((opcion) => (
                  <option key={opcion.idZona} value={opcion.idZona}>
                    {opcion.nombreZona}
                  </option>
                ))}
              </select>
            </div>
            <div className="bloqueTabla__top__child">
              <p>Departamento</p>
              <select
                id="idSelectDep"
                onChange={(e) => rellenarProvincia(e.target)}
              >
                <option value="">Seleccione:</option>
                {dataSelectDep.map((opcion) => (
                  <option
                    key={opcion.idDepartamento}
                    value={opcion.idDepartamento}
                  >
                    {opcion.nombreDepartamento}
                  </option>
                ))}
              </select>
            </div>
            <div className="bloqueTabla__top__child">
              <p>Provincia</p>
              <select
                id="idSelectProv"
                onChange={(e) => rellenarDistrito(e.target)}
              >
                <option value="">Seleccione:</option>
                {dataSelectProv.map((opcion) => (
                  <option key={opcion.idProvincia} value={opcion.idProvincia}>
                    {opcion.nombreProvincia}
                  </option>
                ))}
              </select>
            </div>
            <div className="bloqueTabla__top__child">
              <p>Distrito</p>
              <select
                id="idSelectDis"
                onChange={(e) => cambiarTablaPorDistrito(e.target)}
              >
                <option value="">Seleccione:</option>
                {dataSelectDist.map((opcion) => (
                  <option key={opcion.idDistrito} value={opcion.idDistrito}>
                    {opcion.nombreDistrito}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="bloqueTabla__top">
            <div className="bloqueTabla__top__child">
              <p>Estado Registro</p>
              <select
                onChange={(e) => refresh(e.target)}
                id="mySelect1">
                <option value="">Seleccione:</option>
                <option value="Habilitado">HABILITADO</option>
                <option value="Registrado">REGISTRADO</option>
                <option value="Nuevo">NUEVO</option>
              </select>
            </div>
            <div className="bloqueTabla__top__child">
              <p>Periodo Registro</p>
              <select
                onChange={(e) => refresh(e.target)}
                id="mySelect5">
                <option value="">Seleccione:</option>
                {dataSelectPeriodoReg.map((e) => (
                  <option key={e.orden_Periodo_Registro} value={e.periodo_Registro}>
                    {e.periodo_Registro}
                  </option>
                ))}
              </select>
            </div>
            <div className="bloqueTabla__top__child">
              <p>Rango Periodo 1</p>
              <select
                onChange={(e) => refresh(e.target)}
                id="mySelect6"
              >
                <option value="">Seleccione:</option>
                {dataSelectRangoPer1.map((element) => (
                  <option key={element} value={element}>
                    {element}
                  </option>
                ))}
              </select>
            </div>
            <div className="bloqueTabla__top__child">
              <p>Rango Periodo 2</p>
              <select
                onChange={(e) => refresh(e.target)}
                id="mySelect7">
                <option value="">Seleccione:</option>
                {dataSelectRangoPer2.map((element) => (
                  <option key={element} value={element}>
                    {element}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="bloqueTabla__top">
            <div
              className="bloqueTabla__top__child"
              style={{ height: "min-content" }}
            >
              <p style={{ whiteSpace: "nowrap" }}>Rango Mes</p>
              <select
                onChange={(e) => refresh(e.target)}
                id="mySelect8"
              >
                <option value="">Seleccione:</option>
                {dataSelectRangoMes.map((element) => (
                  <option key={element} value={element}>
                    {element}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="controles">
          <span>Filas por página:</span>
          {/* <Form.Select aria-label="Default select example">
              <option>Open this select menu</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select> */}
          <select onChange={cambiarFilasPorPagina} value={filasPorPagina}>
            {filasPorPaginaOpciones.map((opcion) => (
              <option key={opcion} value={opcion}>
                {opcion}
              </option>
            ))}
          </select>
        </div>
        <div className="bloqueTabla__body">
          <table
            id="table_id"
            className="table table-condensed display nowrap dataTable no-footer"
            cellSpacing="0"
            width="100%"
          >
            <thead>
              <tr>
                <th>Empresario</th>
                <th>Celular</th>
                <th>Zona</th>
                <th>Departamento</th>
                <th>Provincia</th>
                <th>Distrito</th>
                <th>Estado Registro</th>
                <th>Periodo Registro</th>
                <th>Rango 1er Periodo</th>
                <th>Rango 2do Periodo</th>
                <th>Rango Mes</th>
                <th>Rango Mes Anterior</th>
                <th>Periodos Transcurridos</th>
                <th>Periodos Activos</th>
                <th>Periodo 6</th>
                <th>Periodo 5</th>
                <th>Periodo 4</th>
                <th>Periodo 3</th>
                <th>Periodo 2</th>
                <th>Periodo 1</th>
              </tr>
            </thead>
            <tbody>
              {datosPaginados.map((item) => (
                <tr key={item.idcliente}>
                  <td>{item.empresario}</td>
                  <td>{item.celular}</td>
                  <td>{item.zona}</td>
                  <td>{item.departamento}</td>
                  <td>{item.provincia}</td>
                  <td>{item.distrito}</td>
                  <td>{item.estado_Registro}</td>
                  <td>{item.periodo_Registro}</td>
                  <td>{item.rango_Periodo_1}</td>
                  <td>{item.rango_Periodo_2}</td>
                  <td>{item.rango_Mes}</td>
                  <td>{item.rango_Mes_Anterior}</td>
                  <td>{item.periodos_Transcurridos}</td>
                  <td>{item.periodos_Activo}</td>
                  <td>{item.pP_Periodo_6}</td>
                  <td>{item.pP_Periodo_5}</td>
                  <td>{item.pP_Periodo_4}</td>
                  <td>{item.pP_Periodo_3}</td>
                  <td>{item.pP_Periodo_2}</td>
                  <td>{item.pP_Periodo_1}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* <div className="paginacion">
            {Array.from(
              { length: Math.ceil(dataTableFiltersFollows.length / filasPorPagina) },
              (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => cambiarPagina(index + 1)}
                  className={index + 1 === paginaActual ? "pagina-actual" : ""}
                >
                  {index + 1}
                </button>
              )
            )}
          </div> */}
        </div>
          <div id="divPagination">
            <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={(e) => cambiarPagina(e.selected + 1)}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={null}
            className="pagination"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            activeClassName="active"
            />

          </div>
      </section>
    </main>
  );
};

export default Indicadores;
